@import './theme.scss';

@import './home.scss';
@import './header.scss';
@import './settings.scss';
@import './sideMenu.scss';
@import './button.scss';
@import './box.scss';
@import './textInput.scss';
@import './listview.scss';
@import './player.scss';
@import './login.scss';
@import './premium.scss';
@import './dashboard.scss';
@import 'faq.scss';
@import 'termsConditions.scss';
@import 'privacy.scss';
@import "~balloon-css/src/balloon";
@import "toastr";

html {
    height: 100%;
    width: 100%;
    zoom: 0.9;
}

body {
    min-width: 100%;
    min-height: 100%;
}

html, body {
    margin: 0px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
    color: #fff !important;
    background-color: map-get($map: $theme-colors, $key: "BLACK");
}

#root {
   height: 100vh;
   @media only screen and (max-width: $media-break-3) {
        overflow-y: scroll;
        height: unset;
   }
}
.flex {
    display: flex;
}
.full-height {
    height: 100%;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

a, a:hover {
    color: map-get($map: $theme-colors, $key: "ORANGE");
}
.link {
    color: map-get($map: $theme-colors, $key: "TEXT_LIGHT_GREY");

    &:hover {
        color: map-get($map: $theme-colors, $key: "ORANGE");
        text-decoration: underline;
        cursor: pointer;
    }
}
/* button:focus {outline: 0;}
input:focus {outline: none !important;} */
input {border: none !important;}
*:focus { outline: none !important; }

:root {
    --balloon-color: #DA291C;
}

.hidden {
    display: none;
}
.toast {
    background-color: #000 !important;
}
#toast-container {
    &.toast-top-right {
        top: 120px;
    }
    > div {
        box-shadow: none !important;
    }
    > .toast-error {
        background-image: none !important;
        background-color: map-get($map: $theme-colors, $key: "ORANGE") !important;
    }
    
}
//Layout for Smartphone


//Layout for Tablet
@media only screen and (min-width: $media-break-3) {}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {
    html, body {
        font-size: 16px;
    }
}